<template>
  <div>
    <el-form label-position="right">
      <TitleAndDescription :field="field" />
      <el-row type="flex" :gutter="50">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <br />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Integrations">
            <el-select
              v-model="field.integration_settings.selectedIntegrationField"
              no-data-text="No Integrations available"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="integration in filteredIntegrations"
                :value="integration.key"
                :key="integration.key"
                :label="integration.label"
              >
                <span style="float: left">{{ integration.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Event">
            <el-select
              v-model="field.integration_settings.selectedVariableEvent"
              placeholder="Select an Event"
              no-data-text="No Events available"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="event in eventsArray"
                :key="event._id"
                :label="event.eventName"
                :value="event._id"
              >
                <span style="float: left">{{ event.eventName }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Integration Variable">
            <el-select
              v-model="
                field.integration_settings.selectedIntegrationFieldVariable
              "
              placeholder="Select a Variable"
              no-data-text="No Variables available"
              filterable
              style="width: 100%"
              @change="updateSelectedVariable"
            >
              <el-option
                v-for="variable in integrationVariables"
                :key="variable.value"
                :label="variable.label"
                :value="variable.value"
              >
                <span style="float: left">{{ variable.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <div class="form-group">
            <is-field-required :field="field" class="field-required" />
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { postAPICall } from "../../../helpers/httpHelper";

export default {
  props: ["field", "fieldsData", "index"],
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    IsFieldRequired: () => import("./IsFieldRequired"),
  },
  computed: {
    filteredIntegrations() {
      return this.fieldsData.filter(
        (field) =>
          field.input_type && field.input_type.toUpperCase() === "INTEGRATION"
      );
    },
    selectedIntegrationVariable: {
      get() {
        return this.field.integration_settings.selectedIntegrationFieldVariable;
      },
      set(value) {
        this.$set(this.field, "selectedIntegrationFieldVariable", value);
      },
    },
  },
  watch: {
    "field.integration_settings.selectedIntegrationField": function (newVal) {
      this.fetchIntegrationEvents(newVal);
    },
    "field.integration_settings.selectedVariableEvent"(newEvent) {
      this.loadAvailableColumns(newEvent);
    },
  },
  async mounted() {
    await this.fetchAllPredefinedIntegrations();
    await this.fetchIntegrations();
    this.handleIntegrationUpdate();
  },
  data() {
    return {
      formData: {},
      requiredFields: {},
      selectedIntegrationField: null,
      selectedIntegrationFieldVariable: null,
      selectedIntegrationFieldVariableType: null,
      integrationVariables: [],
      selectedVariableEvent: null,
      eventsArray: [],
    };
  },
  methods: {
    handleIntegrationUpdate() {
      if (this.field.integration_settings?.selectedIntegrationField) {
        this.fetchIntegrationEvents(
          this.field.integration_settings.selectedIntegrationField
        );
      }
    },
    updateSelectedVariable(value) {
      const variableChosen = this.integrationVariables.find(
        (variable) => variable.value === value
      );
      if (variableChosen) {
        this.field.integration_settings.selectedIntegrationFieldVariableType =
        variableChosen.type;
      }
    },
    async fetchEventData(requestBody) {
      try {
        const response = await postAPICall(
          "POST",
          `integrations/service/get-event-data`,
          requestBody
        );
        const data = response?.data || {};
        return data;
      } catch (error) {
        return error;
      }
    },
    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
    },
    async fetchIntegrations() {
      let params = {
        limit: 10,
        page: 1,
      };
      let integrationData = await postAPICall("GET", `integrations`, params);
      return integrationData;
    },
    async loadAvailableColumns(eventId) {
      const matchedEvent = this.eventsArray.find(
        (event) => String(event._id) === String(eventId)
      );
      if (
        matchedEvent &&
        matchedEvent.settings &&
        matchedEvent.settings.availableColumns
      ) {
        const integrationKey =
          this.field.integration_settings.selectedIntegrationField;
        const availableVariables = matchedEvent.settings.availableColumns.map(
          (column) => ({
            label: `${integrationKey} - ${column.label}`,
            value: column.key,
            type: column.type,
          })
        );
        const previouslySelectedVariable =
          this.field.integration_settings.selectedIntegrationFieldVariable;
        const isPreviousVariableValid = availableVariables.some(
          (variable) => variable.value === previouslySelectedVariable
        );
        this.integrationVariables = availableVariables;
        if (isPreviousVariableValid) {
          this.field.integration_settings.selectedIntegrationFieldVariable =
            previouslySelectedVariable;
        } else if (availableVariables.length > 0) {
          const selectedVariable = availableVariables[1];
          this.field.integration_settings.selectedIntegrationFieldVariable =
            selectedVariable.value;
          this.field.integration_settings.selectedIntegrationFieldVariableType =
            selectedVariable.type;
        } else {
          this.field.integration_settings.selectedIntegrationFieldVariable =
            null;
          this.field.integration_settings.selectedIntegrationFieldVariableType =
            null;
        }
      } else {
        this.integrationVariables = [];
        this.field.integration_settings.selectedIntegrationFieldVariable = null;
        this.field.integration_settings.selectedIntegrationFieldVariableType =
          null;
      }
    },
    async processIntegrationSelection(integrationKey) {
      try {
        const selectedIntegration = this.filteredIntegrations.find(
          (field) => field.key.toLowerCase() === integrationKey.toLowerCase()
        );
        const integrationSettings = selectedIntegration.integration_settings;
        const applicationSelected = integrationSettings.selectedApplication;
        const application = this.predefinedIntegrations.find(
          (app) => app._id === applicationSelected
        );

        if (application) {
          this.eventsArray = application.events || [];
        }
      } catch (error) {
        this.integrationVariables = [];
        this.field.selectedIntegrationFieldVariable = null;
        this.eventsArray = [];
      } finally {
        this.isLoading = false;
      }
    },
    async fetchIntegrationEvents(integrationKey) {
      try {
        const selectedIntegration = this.filteredIntegrations.find(
          (field) => field.key.toLowerCase() === integrationKey.toLowerCase()
        );

        if (selectedIntegration) {
          const application = this.predefinedIntegrations.find(
            (app) =>
              app._id ===
              selectedIntegration.integration_settings.selectedApplication
          );

          if (application) {
            this.eventsArray = application.events || [];
            const eventSelected =
              this.field.integration_settings.selectedVariableEvent;
            if (eventSelected) {
              this.loadAvailableColumns(eventSelected);
            }
          } else {
            this.eventsArray = [];
          }
        }
      } catch (error) {
        this.eventsArray = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-dialog {
  width: 100%;
}

.dialog-content {
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.dialog-content::-webkit-scrollbar {
  width: 8px;
}

.dialog-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dialog-content::-webkit-scrollbar-thumb {
  background: #888;
}

.dialog-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.event-type-options {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
</style>
